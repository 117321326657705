<template>
  <div id="search_vehicles">
    <section v-if="vehicles.length === 0">
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <div
            v-t="'components.products.no_vehicles_found'"
            class="mb-4 mt-6 bg-orange text-white font-medium uppercase text-lg px-4 py-2">
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="flex flex-wrap">
        <div
          v-for="vehicle in vehicles"
          class="w-full tablet:w-1/3 px-4">
          <div class="py-2">
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group-brand-type-model',
                  params: {
                    group: $slugify(
                      vehicle.productGroup
                        ? vehicle.productGroup
                        : vehicle.fallbackProductGroup
                    ),
                    brand: $slugify(
                      vehicle.brand
                        ? vehicle.brand
                        : vehicle.fallbackBrand
                    ),
                    type: $slugify(
                      vehicle.type
                        ? vehicle.type
                        : vehicle.fallbackType
                    ),
                    model: $slugify(
                      vehicle.model
                        ? vehicle.model
                        : vehicle.fallbackModel
                    )
                  }
                })
              "
              class="text-lg">
              <font-awesome-icon :icon="['fas', 'arrow-right']" class="text-red"/> {{
              `${
                  vehicle.brand
                    ? vehicle.brand
                    : vehicle.fallbackBrand
                } ${
                  vehicle.model
                    ? vehicle.model
                    : vehicle.fallbackModel
                }`
              }}
            </nuxt-link>
          </div>
        </div>
      </div>
  </section>

    <client-only>
      <infinite-loading
        v-if="lastPage > 1"
        v-show="true"
        :identifier="infiniteId"
        @infinite="infiniteScroll"
        error=" "
        no-results=" "
        no-more=" "
        spinner="spiral"
      />
    </client-only>
  </div>
</template>
<script>
import portal from '../../service/portal';

export default {
  name: 'SearchResultsVehicles',
  props: {
    initialData: {
      required: true,
      type: [Object, null]
    },
    query: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      infiniteId: 0,
      itemsLoading: false,
      language: 'en',
      lastPage: 0,
      page: 0,
      vehicles: []
    }
  },
  mounted () {
    this.$set(this, 'language', this.$store.state.language);
    this.$set(this, 'page', this.initialData.current_page);
    this.$set(this, 'lastPage', this.initialData.last_page);
    this.appendVehicles(this.initialData.data);
;
  },
  methods: {
    async infiniteScroll($state) {
      if (!!this.itemsLoading) {
        return;
      }

      this.$set(this, 'page', parseInt(this.page) + 1);
      this.itemsLoading = true;

      const requestResponse = await portal.get(
        `vehicles/search/${this.query}?lang=${this.language}&page=${this.page}`
      )

      this.appendVehicles(requestResponse.data.data);

      if ($state) {
        if (this.page < this.lastPage) {
          $state.loaded();
          this.infiniteId++;
        } else {
          $state.complete();
        }
      }

      setTimeout(() => {
        this.itemsLoading = false;
      }, 200);
    },
    appendVehicles (vehiclesData) {
      for (let i = 0; i < vehiclesData.length; i++) {
        const vehicle = {};
        vehicle.productGroup = '';
        vehicle.brand = '';
        vehicle.type = '';
        vehicle.model = '';
        vehicle.fallbackProductGroup = '';
        vehicle.fallbackBrand = '';
        vehicle.fallbackType = '';
        vehicle.fallbackModel = '';

        for (let j = 0; j < vehiclesData[i].translations.length; j++) {
          if (vehiclesData[i].translations[j].language.language_code === 'en') {
            vehicle.fallbackModel = vehiclesData[i].translations[j].name;
          }
          if (vehiclesData[i].translations[j].language.language_code === this.language) {
            vehicle.model = vehiclesData[i].translations[j].name;
          }
        }
        for (let j = 0; j < vehiclesData[i].vehicle_type.translations.length; j++) {
          if (vehiclesData[i].vehicle_type.translations[j].languagelanguage_code === 'en') {
            vehicle.fallbackType = vehiclesData[i].vehicle_type.translations[j].name;
          }
          if (vehiclesData[i].vehicle_type.translations[j].language.language_code === this.language) {
            vehicle.type = vehiclesData[i].vehicle_type.translations[j].name;
          }
        }
        for (let j = 0; j < vehiclesData[i].vehicle_type.brand.translations.length; j++) {
          if (vehiclesData[i].vehicle_type.brand.translations[j].language.language_code === 'en') {
            vehicle.fallbackBrand = vehiclesData[i].vehicle_type.brand.translations[j].name;
          }
          if (vehiclesData[i].vehicle_type.brand.translations[j].language.language_code === this.language) {
            vehicle.brand = vehiclesData[i].vehicle_type.brand.translations[j].name;
          }
        }
        for (let j = 0; j < vehiclesData[i].vehicle_type.brand.productgroup.translations.length; j++) {
          const translations = vehiclesData[i].vehicle_type.brand.productgroup.translations;
          if (translations[j].language.language_code === 'en') {
            vehicle.fallbackProductGroup = vehiclesData[i].vehicle_type.brand.productgroup.translations[j].name;
          }
          if (translations[j].language.language_code === this.language) {
            vehicle.productGroup = vehiclesData[i].vehicle_type.brand.productgroup.translations[j].name;
          }
        }
        vehicle.link = vehicle.id;
        this.vehicles.push(vehicle);
      }
    }
  }
}
</script>
