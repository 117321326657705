<template>
  <div id="search_products">
    <section v-if="products.length === 0">
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <div
            v-t="'components.products.no_products_found'"
            class="mb-4 mt-6 bg-orange text-white font-medium uppercase text-lg px-4 py-2">
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <div v-for="product in products">
            <product-search-item
              :data="product"
              :link="{
                name: 'search-product',
                params: {
                  product: $slugify(product.link)
                }
              }"
            />
          </div>
        </div>
      </div>
    </section>

    <client-only>
      <infinite-loading
        v-if="lastPage > 1"
        v-show="true"
        :identifier="infiniteId"
        @infinite="infiniteScroll"
        error=" "
        no-results=" "
        no-more=" "
        spinner="spiral"
      />
    </client-only>
  </div>
</template>
<script>
import ProductSearchItem from '../../components/products/SearchItem';
import portal from '../../service/portal';

export default {
  name: 'SearchResultsProducts',
  components: { ProductSearchItem },
  props: {
    initialData: {
      required: true,
      type: [Object, null]
    },
    query: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      infiniteId: 0,
      itemsLoading: false,
      language: 'en',
      lastPage: 0,
      page: 0,
      products: []
    }
  },
  mounted () {
    this.$set(this, 'language', this.$store.state.language);
    this.$set(this, 'page', this.initialData.current_page);
    this.$set(this, 'lastPage', this.initialData.last_page);
    this.appendProducts(this.initialData.data);
;
  },
  methods: {
    async infiniteScroll($state) {
      if (!!this.itemsLoading) {
        return;
      }

      this.$set(this, 'page', parseInt(this.page) + 1);
      this.itemsLoading = true;

      const requestResponse = await portal.get(
        `products/search/${this.query}?lang=${this.language}&page=${this.page}`
      )

      this.appendProducts(requestResponse.data.data);

      if ($state) {
        if (this.page < this.lastPage) {
          $state.loaded();
          this.infiniteId++;
        } else {
          $state.complete();
        }
      }

      setTimeout(() => {
        this.itemsLoading = false;
      }, 200);
    },
    appendProducts (productsData) {
      for (let i = 0; i < productsData.length; i++) {
        const product = productsData[i];

        for (let j = 0; j < productsData[i].translations.length; j++) {
          if (productsData[i].translations[j].language.language_code === 'en') {
            product.fallback_product = productsData[i].translations[j];
          }
          if (productsData[i].translations[j].language.language_code === this.language) {
            product.product = productsData[i].translations[j];
          }
        }
        if(product.product != undefined){
          product.link = product.id + ' ' + product.sku + ' ' + product.product.name;
          this.products.push(product);
        }
      }
    }
  }
}
</script>
