var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"products"}},[_c('bar-breadcrumbs',{attrs:{"data":_vm.breadcrumbs}}),_vm._v(" "),_c('section',{staticClass:"bar__categories"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap flex-col-reverse tablet:flex-row"},[_c('div',{staticClass:"w-full tablet:w-1/4 px-4"},[_c('sidebar')],1),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4 tablet:mt-0 mt-3"},[(_vm.loading)?_c('div',{staticClass:"text-center mt-5"},[_c('header-h2',{attrs:{"content":((_vm.$t('components.search_box.searching')) + ": " + _vm.query),"mt":"0","mb":"4","extra-class":"px-4"}}),_vm._v(" "),_c('font-awesome-icon',{staticClass:"fa-spin fa-3x fa-fw",attrs:{"icon":['fas', 'circle-notch']}})],1):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',[_c('header-h2',{attrs:{"content":((_vm.$t('components.search_box.search')) + ": " + _vm.query),"mt":"0","mb":"4","extra-class":"px-4"}})],1):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"flex flex-wrap mb-4"},[_c('div',{staticClass:"w-full px-4"},[_c('button',{class:("button button--" + (_vm.showView === 'products'
                      ? 'secondary--dark button--without-skew'
                      : 'tertiary')),attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(
                  ((_vm.$t('components.search_box.products')) + " (" + _vm.totalProducts + ")")
                )},on:{"click":function($event){return _vm.changeView('products')}}}),_vm._v(" "),_c('button',{class:("button button--" + (_vm.showView === 'vehicles'
                      ? 'secondary--dark button--without-skew'
                      : 'tertiary')),attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(
                  ((_vm.$t('components.search_box.vehicles')) + " (" + _vm.totalVehicles + ")")
                )},on:{"click":function($event){return _vm.changeView('vehicles')}}})])]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('search-results-products',{directives:[{name:"show",rawName:"v-show",value:(_vm.showView === 'products'),expression:"showView === 'products'"}],attrs:{"initial-data":_vm.initialProducts,"query":_vm.query}}):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('search-results-vehicles',{directives:[{name:"show",rawName:"v-show",value:(_vm.showView === 'vehicles'),expression:"showView === 'vehicles'"}],attrs:{"initial-data":_vm.initialVehicles,"query":_vm.query}}):_vm._e()],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }