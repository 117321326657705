var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"search_vehicles"}},[(_vm.vehicles.length === 0)?_c('section',[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full px-4"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:('components.products.no_vehicles_found'),expression:"'components.products.no_vehicles_found'"}],staticClass:"mb-4 mt-6 bg-orange text-white font-medium uppercase text-lg px-4 py-2"})])])]):_c('section',[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.vehicles),function(vehicle){return _c('div',{staticClass:"w-full tablet:w-1/3 px-4"},[_c('div',{staticClass:"py-2"},[_c('nuxt-link',{staticClass:"text-lg",attrs:{"to":_vm.localePath({
                name: 'products-group-brand-type-model',
                params: {
                  group: _vm.$slugify(
                    vehicle.productGroup
                      ? vehicle.productGroup
                      : vehicle.fallbackProductGroup
                  ),
                  brand: _vm.$slugify(
                    vehicle.brand
                      ? vehicle.brand
                      : vehicle.fallbackBrand
                  ),
                  type: _vm.$slugify(
                    vehicle.type
                      ? vehicle.type
                      : vehicle.fallbackType
                  ),
                  model: _vm.$slugify(
                    vehicle.model
                      ? vehicle.model
                      : vehicle.fallbackModel
                  )
                }
              })}},[_c('font-awesome-icon',{staticClass:"text-red",attrs:{"icon":['fas', 'arrow-right']}}),_vm._v(" "+_vm._s(((vehicle.brand
                  ? vehicle.brand
                  : vehicle.fallbackBrand) + " " + (vehicle.model
                  ? vehicle.model
                  : vehicle.fallbackModel)))+"\n          ")],1)],1)])}),0)]),_vm._v(" "),_c('client-only',[(_vm.lastPage > 1)?_c('infinite-loading',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"identifier":_vm.infiniteId,"error":" ","no-results":" ","no-more":" ","spinner":"spiral"},on:{"infinite":_vm.infiniteScroll}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }