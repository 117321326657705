<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar />
          </div>
          <div class="w-full tablet:w-3/4 tablet:mt-0 mt-3">
            <div v-if="loading" class="text-center mt-5">
              <header-h2
                :content="`${$t('components.search_box.searching')}: ${query}`"
                mt="0"
                mb="4"
                extra-class="px-4"
              />
              <font-awesome-icon :icon="['fas', 'circle-notch']" class="fa-spin fa-3x fa-fw"/>
            </div>

            <div v-if="!loading">
              <header-h2
                :content="`${$t('components.search_box.search')}: ${query}`"
                mt="0"
                mb="4"
                extra-class="px-4"
              />
            </div>

            <div v-if="!loading" class="flex flex-wrap mb-4">
              <div class="w-full px-4">
                <button
                  v-html="
                    `${$t('components.search_box.products')} (${totalProducts})`
                  "
                  @click="changeView('products')"
                  :class="
                    `button button--${
                      showView === 'products'
                        ? 'secondary--dark button--without-skew'
                        : 'tertiary'
                    }`
                  "
                  type="button">
                </button>
                <button
                  v-html="
                    `${$t('components.search_box.vehicles')} (${totalVehicles})`
                  "
                  @click="changeView('vehicles')"
                  :class="
                    `button button--${
                      showView === 'vehicles'
                        ? 'secondary--dark button--without-skew'
                        : 'tertiary'
                    }`
                  "
                  type="button">
                </button>
              </div>
            </div>
            <search-results-products
              v-if="!loading"
              v-show="showView === 'products'"
              :initial-data="initialProducts"
              :query="query" />

            <search-results-vehicles
              v-if="!loading"
              v-show="showView === 'vehicles'"
              :initial-data="initialVehicles"
              :query="query" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs';
import portal from '../../service/portal';
import SearchResultsProducts from './SearchResultsProducts.vue';
import SearchResultsVehicles from './SearchResultsVehicles.vue';
import Sidebar from '../../components/bars/Sidebar';

export default {
  name: 'Products',
  components: {
    Sidebar,
    SearchResultsProducts,
    SearchResultsVehicles,
    BarBreadcrumbs
  },
  head() {
    const title = this.$i18n.t('components.search_box.search') + ' - Air Conditioning Parts - AP Air Ltd.';
    const description = '';

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ]
    }
  },
  data() {
    return {
      initialProducts: null,
      initialVehicles: null,
      loading: true,
      showView: 'products',
      totalProducts: 0,
      totalVehicles: 0
    }
  },
  asyncData({ app, query, store }) {
    const breadcrumbs = [
      {
        name: app.i18n.t('menu.home'),
        url: 'index'
      },
      {
        name: app.i18n.t('components.search_box.search') + ' ' + query.q,
        url: {
          name: 'search',
          query: {
            q: query.q
          }
        }
      }
    ]

    return {
      breadcrumbs,
      query: query.q
    }
  },
  created() {
    this.loadFirstPage();
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Products',
        page_path: this.$route.fullPath
      })
    }

    if (process.client && this.$auth.loggedIn && !this.$store.getters.getIsFavoritesSet) {
      this.loadFavorites();
    }
  },
  methods: {
    async loadFirstPage () {
      const [products, vehicles] = await Promise.all([
        portal.get(
          `products/search/${this.query}?lang=${this.$store.state.language}&page=1`
        ),
        portal.get(
          `vehicles/search/${this.query}?lang=${this.$store.state.language}&page=1`
        )
      ])

      this.initialProducts = products.data;
      this.initialVehicles = vehicles.data;
      this.totalProducts = products.data.total;
      this.totalVehicles = vehicles.data.total;

      this.loading = false;
    },
    changeView(view) {
      this.showView = view;
    },
    async loadFavorites () {
      portal.get(`favourites/get-all/${this.$auth.user.user_id}`)
      .then((response) => {
        this.$store.commit('SET_FAVORITE_ITEMS', response.data.products.map(item => item.product_id));
        this.$store.commit('SET_FAVORITE_ITEMS_FETCHED', true);
      });
    }
  }
}
</script>